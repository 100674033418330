// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

/*
* Mixin: Font Face
* Миксин для подключения шрифтов
*
* @param {variable} $name - Имя шрифта
* @param {variable} $file - Путь до шрифта
* @param {variable} $weight - Толщина шрифта
* @param {variable} $style - Начертание шрифта
*/
@mixin font-face($name, $file, $weight: normal, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    font-display: auto; // определяет то, как шрифт, подключенный через font face будет отображаться в зависимости от того, загрузился ли он и готов ли к использованию
    src: url('../fonts/#{$file}.eot');
    src: url('../fonts/#{$file}.eot?#iefix') format('embedded-opentype'),
    url('../fonts/#{$file}.woff') format('woff'),
    url('../fonts/#{$file}.ttf') format('truetype'),
    url('../fonts/#{$file}.svg?#webfont') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

/*
* Mixin: Font Face OTF
* Миксин для подключения шрифтов OTF
*
* @param {variable} $name - Имя шрифта
* @param {variable} $file - Путь до шрифта
* @param {variable} $weight - Толщина шрифта
* @param {variable} $style - Начертание шрифта
*/
@mixin font-face-otf($name, $file, $weight: normal, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    font-weight: $weight;
    font-style: $style;
    src: url('../fonts/#{$file}.otf') format('opentype');
  }
}

/*
* Mixin: Font Face TTF
* Миксин для подключения шрифтов TTF
*
* @param {variable} $name - Имя шрифта
* @param {variable} $file - Путь до шрифта
* @param {variable} $weight - Толщина шрифта
* @param {variable} $style - Начертание шрифта
*/
@mixin font-face-ttf($name, $file, $weight: normal, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    font-weight: $weight;
    font-style: $style;
    src: url('../fonts/#{$file}.ttf') format('truetype');
  }
}

/*
* Mixin: Title Height
* Ограничивает высоту заголовка несколькими строчками
*
* @param {variable} $font-size [14px] - Размер шрифта
* @param {variable} $lines-count [14px] - Количество строк
* @param {variable} $line-height [14px] - Высота строки
*/
@mixin title-height($font-size, $lines-count, $line-height: $font-size) {
  font-size: $font-size !important;
  line-height: $line-height !important;
  display: block;
  overflow: hidden;
  max-height: $line-height*$lines-count;
  height: $line-height*$lines-count;
  position: relative;
  &:after {
    /*
     * Высветляем конец последней строки
     */
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30%;
    height: $line-height;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
  }
}

/* Mixin: Fluid Type
*
* Magic calc + vh to allow text to be fluid between minimum
* and maximum breakpoints.
*
* @group typography
* @param {variable} $min-font-size [12px] - Минимальный размер шрифта
* @param {variable} $max-font-size [24px] - Максимальный размер шрифта
* @param {variable} $lower-range [420px] - Остановить масштабирование при ширине экрана меньше заданного
* @param {variable} $upper-range [900px] - Остановить масштабирование при ширине экрана больше заданного
* @example
*   h1 {
*     @include responsive-type(20px, 48px);
*   }
*/
@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: 420px, $upper-range: 900px) {
  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ((100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range) {
    font-size: $max-font-size;
  }
}

/* Mixin: Fluid Line Height
*
* Magic calc + vh to allow text to be fluid between minimum
* and maximum breakpoints.
*
* @group typography
* @param {variable} $min-font-size [12px] - Минимальный размер шрифта
* @param {variable} $max-font-size [24px] - Максимальный размер шрифта
* @param {variable} $lower-range [420px] - Остановить масштабирование при ширине экрана меньше заданного
* @param {variable} $upper-range [900px] - Остановить масштабирование при ширине экрана больше заданного
* @example
*   h1 {
*     @include responsive-type(20px, 48px);
*   }
*/
@mixin fluid-line-height($min-line-height: 12px, $max-line-height: 21px, $lower-range: 420px, $upper-range: 900px) {
  line-height: calc(#{$min-line-height} + #{(($max-line-height / ($max-line-height * 0 + 1)) - ($min-line-height / ($min-line-height * 0 + 1)))} * ((100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  @media screen and (max-width: $lower-range) {
    line-height: $min-line-height;
  }
  @media screen and (min-width: $upper-range) {
    line-height: $max-line-height;
  }
}

