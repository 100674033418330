/*
Памятка:
100 - Thin        - Сверхтонкое начертание
200 - Extra-light -
300 - Light       -
400 - Regular     - Нормальное начертание (установлено по-умолчанию)
500 - Medium      - 
600 - Semi-bold   -
700 - Bold        - Стандартный полужирный текст
800 - Extra-bold  -
900 - Black       - Сверхжирное начертание
*/

@import '../mixins/_fonts';

@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&subset=cyrillic');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

//@include font-face-otf('ProximaNova', $path_fonts + 'ProximaNova/ProximaNova-Thin', 100);
//@include font-face-otf('ProximaNova', $path_fonts + 'ProximaNova/ProximaNova-Light', 300);
//@include font-face-otf('ProximaNova', $path_fonts + 'ProximaNova/ProximaNova-Regular', 400);
//@include font-face-otf('ProximaNova', $path_fonts + 'ProximaNova/ProximaNova-Medium', 500);
//@include font-face-otf('ProximaNova', $path_fonts + 'ProximaNova/ProximaNova-Bold', 700);
//@include font-face-otf('ProximaNova', $path_fonts + 'ProximaNova/ProximaNova-Black', 900);

$font-main: 'Rubik', sans-serif;
$font-second: 'Rubik', sans-serif;
$font-numbers: 'Montserrat', sans-serif;

/*
 * Font Weight
 */
.font {
  &--weight {
    @for $i from 1 through 9 {
      &-#{$i*100} {
        font-weight: $i*100 !important;
      }
    }
  }
}
