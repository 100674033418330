@import "mixins/grid";
// @import "mixins/colors";
// @import "mixins/inputs";
@import "parts/vars";
@import "parts/fonts";
// @import "parts/uikit";
// @import "parts/uikit-extends";
// @import "parts/typography";
// @import "parts/buttons";
// @import "parts/settings";
// @import "parts/video";
// @import "parts/blocks";
// @import "parts/mobile";
// @import "parts/inputs";
// @import "parts/aspect-ratio";

html {
  // Убираем серое выделение при тапе на ссылках
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
.content-area {
  font-family: $font-main;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  color: #5B5F6E;

  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  a {
    color: $color-primary;

    &:hover {
      color: darken($color-primary, 10%);
    }
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h4 {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h5 {
    display: block;
    font-size: .83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h6 {
    display: block;
    font-size: .67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  b {
    font-weight: 500;
  }

  ul {
    list-style-type: disc;
    padding-left: 1em;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1em;
  }
}

.main-navbar {
  display: flex;

  &>.menu-item {
    margin: 0 10px;

    &:hover {
      a {
        color: $color-primary;
      }
    }
  }
}

.footer-navbar {
  display: flex;


  &>.menu-item {
    margin: 0 15px;

    @include respond-to(medium) {
      margin-bottom: 10px;
    }

    &:hover {
      a {
        color: $color-primary;
      }
    }
  }
}

// Хлебные крошки
.breadcrumbs {
  &__container {
    margin-bottom: 20px;
  }

  &__list {
    font-size: 16px;
    font-weight: 600;
    max-width: 100%;
    display: flex !important;
  }

  &__item {
    margin-right: 7px !important;
    margin-top: 0 !important;

    &:last-child {
      * {
        &:after {
          content: none;
        }
      }
    }
  }

  &__link {
    color: #9b9b9b;
    font-weight: 400;
    white-space: nowrap !important;
    display: flex;
    align-items: center;

    span {
      color: inherit;
    }

    &:hover {
      color: #2a2a44;
    }

    &:after {
      content: '';
      display: block;
      width: 7px;
      height: 7px;
      margin-left: 7px;
      background: url("data:image/svg+xml;charset=UTF-8, %3csvg width='4px' height='7px' viewBox='0 0 4 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Снабсервис' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Каталог' transform='translate(-206.000000, -214.000000)' fill='%239B9B9B'%3e%3cg id='Group-8' transform='translate(120.000000, 204.000000)'%3e%3cpath d='M89.803297,13.024606 C89.9344715,13.15592 90,13.3279076 90,13.5000159 C90,13.6721241 89.9344715,13.8443531 89.803297,13.9754257 C89.803297,13.9754257 87.4007436,16.5632053 87.1551001,16.8091777 C86.9092214,17.0551501 86.4671101,17.0719265 86.2045257,16.8091777 C85.9422942,16.546791 85.921471,16.180367 86.204761,15.8583579 L88.408729,13.4998952 L86.204761,11.1415531 C85.921471,10.8194234 85.9424119,10.45312 86.2045257,10.1906126 C86.4672278,9.92810523 86.9092214,9.94488156 87.1551001,10.190854 C87.4007436,10.4368264 89.803297,13.024606 89.803297,13.024606 Z' id='Chevron-right-Copy-2' transform='translate(88.000000, 13.500000) scale(1, -1) translate(-88.000000, -13.500000) '%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e") no-repeat center;
      background-size: contain;
    }
  }

  &__title {
    color: #9b9b9b;
    font-weight: 400;
    white-space: nowrap !important;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
}

body {
  background: #fff; // для правильной работы mmenu
}

.mm-menu {
  --mm-listitem-size: 45px;
  --mm-color-background: #fff;
}

#mobile-nav:not(.mm-menu) {
  display: none;
}

.header-mobile {
  &__container {
    background-color: #fff;
    padding: 10px 0;
  }
}

// mmenu мобильная шторка
.mmenuNav {
  &__container {
    .mm {
      &-menu {
        --mm-listitem-size: 40px; // высота элемента меню
      }

      &-navbar {
        background: #fff !important;

        &__title {
          height: 100%;
          padding: 0 15px;
        }
      }

      &-panel {
        background: #fff !important;

        &:before {
          content: '';
          display: block;
          height: 0px !important;
        }
      }

      &-panels {
        background: #fff !important;
      }

      &-listview {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      &-listitem {
        &:after {
          //content: unset !important;
          left: 0 !important;
          right: 0 !important;
          padding-left: 15px !important;
        }
      }

      &-listitem__btn {
        border: unset !important;
      }

      &-wrapper {
        &_opened {
          .mm-slideout {
            overflow: hidden !important;
          }
        }
      }
    }

    &+.mm-slideout {}

    .current-menu-item,
    .current-menu-parent,
    .current_page_item,
    .current_page_parent {
      color: #fff;
      background-color: $color-primary;

      a {
        &:after {
          border-color: #fff;
        }
      }
    }
  }

  &__catalog {
    margin-bottom: 0 !important;
  }

  &__footer {
    padding: 30px 20px;
  }

  &__phone {
    font-size: 20px;
    font-weight: 300;

    span {
      font-size: 20px;
      font-weight: 600;
    }
  }

}