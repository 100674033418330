/*
 * Пути
 */
$path_assets: '../';
$path_images: $path_assets+'img/';
$path_fonts: $path_assets+'fonts/';
$path_svg: $path_assets+'svg/';

/*
 * Брэйкпоинты
 */
$breakpoint-small: 640px;
$breakpoint-medium: 960px;
$breakpoint-large: 1200px;
$breakpoint-xlarge: 1600px;
$breakpoint-xsmall-max: ($breakpoint-small - 1);
$breakpoint-small-max: ($breakpoint-medium - 1);
$breakpoint-medium-max: ($breakpoint-large - 1);
$breakpoint-large-max: ($breakpoint-xlarge - 1);

/*
 * Цвета
 */
$color-text: #374447;

$color-primary: #1463be;
$color-primary-svg: '%26D9232A';
$color-secondary: #1463be;
$color-success: #7ed321;
$color-warning: #ffd600;
$color-danger: #d9232a;

$gradient-primary: linear-gradient(270deg, #3180DD 0%, #236EC7 100%);
$gradient-secondary: linear-gradient(270deg, #3180DD 0%, #236EC7 100%);
$gradient-warning: linear-gradient(225deg, #FFAE00 0%, #FF7600 100%);

$color-gray-bg: #f7f7f7;
$color-gray-text: #e6e7e8;
$color-gray-border: #e6e7e8;

// для использования в циклах
$colors: (primary: $color-primary,
        secondary: $color-secondary,
        info: #03a9f4,
        success: $color-success,
        warning: $color-warning,
        danger: $color-danger);

/*
 * Цвета брендов
 */
$color-vk: #4a76a8;
$color-viber: #8E24AA;
$color-whatsapp: #4CAF50;
$color-telegram: #039BE5;
$color-ok: #FF9F00;
$color-fb: #3b5998;
$color-twitter: #1dcaff;
$color-youtube: #FF2121;
$color-instagram: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);